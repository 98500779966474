<template>
  <div class="magazine-casamx">
    <b-container class="pt-5">
      <b-row>
        <b-col col md="12" class=" mb-5">
          <section v-for="magazine in magazinePages" :key="magazine.id">
            <h1>
              Casa de Mexico : {{ magazine.title }} -
              {{ $route.params.canonical }}
            </h1>
          </section>
        </b-col>
      </b-row>
    </b-container>

    <div class="blue-grey pb-5">
      <flipbook class="flipbook pb-5 pt-4" :pages="mPages" v-slot="flipbook">
        <div class="menu p-3 text-center">
          <button class="btn btn-light m-1" @click="flipbook.flipLeft">
            <i class="fas fa-angle-double-left"></i>
          </button>
          <span class="page-num btn btn-light m-1">
            Pagina {{ flipbook.page }} de {{ flipbook.numPages }}
          </span>
          <button class="btn btn-light m-1" @click="flipbook.flipRight">
            <i class="fas fa-angle-double-right"></i>
          </button>
          <button class="btn btn-light m-1" @click="flipbook.zoomOut">
            <i class="fas fa-search-minus"></i>
          </button>
          <button class="btn btn-light m-1" @click="flipbook.zoomIn">
            <i class="fas fa-search-plus"></i>
          </button>
        </div>
      </flipbook>
    </div>
  </div>
</template>

<script>
import Flipbook from "flipbook-vue";

export default {
  name: "MagazineCasaMxPage",
  components: {
    Flipbook,
  },

  props: {
    cfgTitle: String,
  },

  data() {
    return {
      magazinePages: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      pageNum: null,
      mPages: [
        null,
        "http://localhost:1337/uploads/Mesa_de_trabajo_1_80_57fc0dfb3a.jpg",
        "http://localhost:1337/uploads/Mesa_de_trabajo_2_80_c21654bdc4.jpg",
        "http://localhost:1337/uploads/Mesa_de_trabajo_3_80_bc6ca21666.jpg",
        "http://localhost:1337/uploads/Mesa_de_trabajo_4_80_40139819e1.jpg",
        "http://localhost:1337/uploads/Mesa_de_trabajo_5_80_b3d6a9412b.jpg",
        "http://localhost:1337/uploads/Mesa_de_trabajo_6_80_d65ff0ef41.jpg",
      ],
    };
  },

  mounted() {
    document.title =
      "Casa de Mexico | Instituto de los Mexicanos en el Exterior";
  },

  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint +
        "/magazines/?canonical=" +
        this.$route.params.canonical
      //this.path.endpoint + '/magazines/?canonical=octubre-2021'
    )
      .then((response) => response.json())
      .then((data) => {
        this.magazinePages = data[0].pages;
        console.log("PAGES");
        console.log(data[0].pages);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */

  methods: {},
};
</script>

<style>
.flipbook {
  width: 90vw;
  height: 90vh;
  margin-bottom: 75px;
}
.flipbook .menu {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
